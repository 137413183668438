export const refreshWechatAppSessionQuery = () => {
  return {
    query: `mutation(
      $code: String!
    ) {
      loginByWechatOa(code: $code) {
        sessionToken
        user {
          id
          nickname
          avatarUrl
          phoneNum
        }
        isNewbie
      }
    }`
  }
}

export const referralUserQuery = () => {
  return {
    query: `mutation(
      $referralUserId: String!,
      $userId: String!
    ) {
      referralUser(referralUserId: $referralUserId, userId: $userId) {
        isOk
      }
    }`
  }
}