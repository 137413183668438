import router from '../router/index'
import wx from 'weixin-js-sdk'
import { getWxConfig } from '../http/api'


//判断是否是 微信浏览器
function isWxBrowser() {
  var ua = navigator.userAgent.toLowerCase();
  let isWx = ua.match(/MicroMessenger/i) == "micromessenger";
  if (!isWx) {
    return false;
  } else {
    let isWxWork = ua.match(/WxWork/i) == "wxwork";
    if (isWxWork) {
      return false;
    } else {
      return true;
    }
  }
}


const wxutil = { 
  init(){
    router.afterEach(() => {
      if (isWxBrowser()) {
        getWxConfig({url: encodeURI(window.location.href)}).then(res => {
          console.log(res)
          wx.config({
            debug: false,
            appId: res.appId,
            timestamp: res.timestamp,
            nonceStr: res.nonceStr,
            signature: res.signature,
            jsApiList: [
              "updateAppMessageShareData",
              "updateTimelineShareData"
            ],
          });
          let userInfo = localStorage.getItem('userInfo')
          if (userInfo) userInfo = JSON.parse(userInfo)
          else userInfo = {}
          wx.ready(() => {
            let link = `https://floradapt-page.walle-walle.com?referral=${userInfo.id}`
            let share = {
              title: "分享标题",
              desc: "分享文案",
              link: link,
              imgUrl: 'https://file.walle-walle.com/walle/112c88a068f146a295aeb23da54e18f7/44c578e895df4ef88687506a919cfb76',
            };
  
            wx.updateAppMessageShareData({
              title: share.title,
              link: share.link,
              imgUrl: share.imgUrl,
              desc: share.desc,
              success() {
                console.log("分享成功")
              },
              cancel() {
                console.log("取消分享")
              },
              fail() {
                console.log("分享失败")
              },
            }),
            wx.updateTimelineShareData({
              title: share.desc,
              link: share.link,
              imgUrl: share.imgUrl,
              success() {
                console.log("分享成功")
              },
              cancel() {
                console.log("取消分享")
              },
              fail() {
                console.log("分享失败")
              },
            });
          })
          wx.error((err) => {
            console.log(err);
          });
        })
      }
    })

  },
}; 
export default wxutil;