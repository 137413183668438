import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../pages/index'
import Lipigo from '../pages/lipigo'
import Personal from '../pages/personal'
import Poster from '../pages/poster'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/lipigo',
    name: 'Lipigo',
    component: Lipigo
  },
  {
    path: '/personal',
    name: 'Personal',
    component: Personal
  },
  {
    path: '/poster',
    name: 'Poster',
    component: Poster
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
