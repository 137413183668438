<template>
  <div class="container">
    <div class="video">
      <video v-if="showVideo" class="hero-video-actual" autoplay loop muted playsinline poster="@/assets/images/ad.png">
        <source src="@/assets/ad.mp4" type="video/mp4">
      </video>
      <img v-else src="@/assets/images/ad.png" alt="">
      <div class="title">
        <img src="@/assets/images/logo.png" mode="widthFix" />
        <div class="sub-title">Floradapt® was born to help today’s consumers keep their bodies healthy and balanced by pursuing the balance of the microbiome.</div>
      </div>
      <div class="button" @click="toShop">了解更多产品详情</div>
    </div>
    <div class="section">
      <div class="section-title">关于我们</div>
      <div class="section-content">Floradapt 花神益生菌来自西班牙的专业益生菌品牌，呵护身心健康，畅想舒适生活。它通过科学的解决方案来平衡人体内微生物群，保持现代人的身体健康和体内生态平衡。 经过临床试验验证，Floradap益生菌拥有了40多项专利，和多方机构的认证，如QPS, GRAS和FDA认证。自13年以来，Floradapt的团队和产品已经跨越50多个国家和地区。</div>
    </div>
    <div class="section">
      <div class="section-title">我们的产品</div>
      <van-collapse class="collapse" v-model="activeNames" @click="onChange">
        <van-collapse-item title="肠道敏感" name="1">
          <p>Digestive Wellbeing 肠道益生菌</p>
          <a href="https://shop1746899206.v.weidian.com/item.html?itemID=5194533701"><img src="@/assets/images/digestive.jpg" mode="widthFix" style="width: 100%;" /></a>
        </van-collapse-item>
        <van-collapse-item  title="女性专区" name="2">
          <p>Women's Protect 女性蔓越莓（原青花素）益生菌</p>
          <a href="https://shop1746899206.v.weidian.com/item.html?itemID=5198379814"><img src="@/assets/images/protect.jpg" mode="widthFix" style="width: 100%;" /></a>
          <p>Pure Skin 净痘益生菌</p>
          <a href="https://shop1746899206.v.weidian.com/item.html?itemID=5197549971"><img src="@/assets/images/skin.jpg" mode="widthFix" style="width: 100%;" /></a>
          <p>Women's Intimate Balance 女性私处专用益生菌</p>
          <a href="https://shop1746899206.v.weidian.com/item.html?itemID=5198351958"><img src="@/assets/images/balance.jpg" mode="widthFix" style="width: 100%;" /></a>
        </van-collapse-item>
        <van-collapse-item  title="家庭健康" name="3">
          <p>Healthy Heart 心脏益生菌</p>
          <a href="https://shop1746899206.v.weidian.com/item.html?itemID=5198437184"><img src="@/assets/images/heart.jpg" mode="widthFix" style="width: 100%;" /></a>
          <p>Immune Booster Plus 抵御升级款益生菌</p>
          <a href="https://shop1746899206.v.weidian.com/item.html?itemID=5198347410"><img src="@/assets/images/booster.jpg" mode="widthFix" style="width: 100%;" /></a>
        </van-collapse-item>
        <van-collapse-item  title="身材管理" name="4">
          <p>Lipigo 热控粉</p>
          <a href="https://shop1746899206.v.weidian.com/item.html?itemID=5198306038"><img src="@/assets/images/lipigo1.jpg" mode="widthFix" style="width: 100%;" /></a>
        </van-collapse-item>
        <van-collapse-item  title="情绪舒压" name="5">
          <p>Mind & Calm</p>
          <a href="https://shop1746899206.v.weidian.com/item.html?itemID=5198460350"><img src="@/assets/images/mind.jpg" mode="widthFix" style="width: 100%;" /></a>
        </van-collapse-item>
      </van-collapse>
    </div>
    <div class="btns">
      <div class="btn-item" @click="toShop">了解更多产品详情</div>
    </div>
  </div>

</template>
<script>
export default {
  data() {
    return {
      showVideo: false,
      activeNames: [],
      code: ''
    }
  },
  mounted() {
    setTimeout(() => this.showVideo = true, 200)
  },
  methods: {
    toShop() {
      window.location.href = 'https://weidian.com/?userid=1746899206'
    },
    toLipigo() {
      this.$router.push('/lipigo')
    },
    onChange(event) {
      console.log(event)
      this.setData({
        activeNames: event.detail,
      });
    },
  },
}
</script>
<style scoped>
.container {
  padding-bottom: .88rem;
  width: 100vw;
}

.video {
  position: relative;
  font-size: 0;
  width: 100vw;
}

.video video {
  width: 100vw;
  /* height: 100vh; */
}

.video img {
  width: 100vw;
  /* height: 100vh; */
}

.title {
  position: absolute;
  top: .76rem;
  left: .32rem;
  height: 1rem;
}

.title img {
  width: 2.32rem;
}

.title .sub-title {
  width: 6.86rem;
  font-weight: 400;
  font-size: .26rem;
  line-height: .36rem;
  letter-spacing: 0.03em;
  color: #FFFFFF;
  margin-top: .38rem;
}

.button {
  width: 6.86rem;
  height: 1.04rem;
  border: .06rem solid #FFFFFF;
  border-radius: .6rem;
  box-sizing: border-box;
  position: absolute;
  bottom: .68rem;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  font-size: .36rem;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #FFFFFF;
}

.section {
  margin-top: .60rem;
  padding: 0 .32rem;
  box-sizing: border-box;
}

.section-title {
  font-style: normal;
  font-weight: 700;
  font-size: .36rem;
  line-height: 1;
  letter-spacing: 0.1em;
  color: rgba(0, 0, 0, 0.8);
}

.section-content {
  margin-top: .50rem;
  font-style: normal;
  font-weight: 400;
  font-size: .26rem;
  letter-spacing: 0.01em;
  color: rgba(0, 0, 0, 0.8);
}

.collapse {
  margin-top: .24rem;
  border-top: none;
}

.btns {
  margin-top: .88rem;
  padding: 0 .32rem;
  box-sizing: border-box;
}

.btn-item {
  width: 100%;
  height: 1.04rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  border: .05rem solid #000000;
  border-radius: .60rem;
  font-weight: 900;
  font-size: .26rem;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #000000;
}
</style>

<style>
  .container .van-cell {
    font-size: .26rem;
    padding: 0;
    height: .96rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #DCDCDC;
  }
  .container .van-collapse-item__content {
    padding: 0;
  }

  .container  .van-cell__left-icon, .container  .van-cell__right-icon {
    font-size: .32rem;
  }
  .container .van-cell__title {
    font-weight: 500;
  }
</style>