<!--pages/poster/poster.wxml-->
<template>
  <div class="container">
    <div class="html2canvas" ref="html2canvas">
      <div class="content">
        <img src="@/assets/images/logo_b.png" alt="" class="logo">
        <div class="user-name">{{userInfo.username}}</div>
        <div class="text">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh </div>
        <div class="poster">
          <img :src="images[imageIndex]" alt="" class="poster-img">
          <vue-qr :text="shareUrl" :size="200" class="qr-code" :style="styles[imageIndex]"></vue-qr>
        </div>
      </div>
    </div>
    <div v-if="imgUrl">
      <img :src="imgUrl" class="img" />
      <div class="btn">
        <button>长按保存/分享引荐海报</button>
      </div>
    </div>
    
  </div>
</template>
<script>
import html2canvas from 'html2canvas'
import vueQr from 'vue-qr'
import poster1 from '../../assets/images/poster1.png'
import poster2 from '../../assets/images/poster2.png'
import poster3 from '../../assets/images/poster3.png'
import { Toast } from 'vant'
import Vue from 'vue'
Vue.use(Toast)
export default {
  components: {
      vueQr
    },
  data () {
    return {
      imgUrl: '',
      images: [poster1, poster2, poster3],
      imageIndex: 0,
      userInfo: {},
      shareUrl: '',
      styles: [
        {left: '1.5rem', bottom: '2.5rem'},
        {right: '1.45rem', bottom: '2.5rem'},
        {left: '1.1rem', bottom: '2.1rem'},
      ]
    }
  },
  mounted () { 
    Toast.loading({
      message: '加载中...',
      forbidClick: true,
      loadingType: 'spinner',
      duration: 0
    })
    let userInfo = localStorage.getItem('userInfo')
    if (userInfo) {
      this.userInfo = JSON.parse(userInfo)
      let url = encodeURIComponent(`https://floradapt-page.walle-walle.com?referral=${this.userInfo.id}`)
      this.shareUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxa3aea9c5883b8ac5&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=state#wechat_redirect`
    }
    this.imageIndex = Math.floor(Math.random() * 4) 
    this.$nextTick(() => {
      setTimeout(() => this.toImage(), 1000)
      Toast.clear()
    })
  },
  methods: {
    toImage () {
      let canvas = this.$refs.html2canvas
      console.log(canvas)
      html2canvas(this.$refs.html2canvas, {
        backgroundColor: null,
        useCORS: true // 解决文件跨域问题
      }).then(canvas => {
        const url = canvas.toDataURL('image/png') // 生成的图片
        // 可以上传后端或者直接显示
        this.imgUrl = url
      })   
    }
  }
}
</script>
<style scoped>
.container {
  padding: 0 .32rem;
  box-sizing: border-box;
  padding-bottom: .4rem !important;
  width: 100vw;
  overflow: hidden;
  font-size: 0;
}
.html2canvas {
  position: absolute;
  left: -999999px;
  top: -9999999px;
}
.content {
  width: 100vw;
}
.logo {
  width: 2.3rem;
  margin: .5rem auto;
  display: block;
}

.user-name {
  border-top: 1px solid #DCDCDC;
  padding-top: .26rem;
  font-weight: 400;
  font-size: .32rem;
  line-height: .36rem;
  text-align: center;
  letter-spacing: 0.03em;
  color: #B47777;
}

.text {
  width: 6rem;
  margin: .1rem auto;
  font-weight: 400;
  font-size: .32rem;
  line-height: .36rem;
  text-align: center;
  letter-spacing: 0.03em;
  color: #000000;
}

.poster {
  position: relative;
}

.poster .poster-img {
  width: 100%;
}

.qr-code {
  width: 1.9rem;
  height: 1.9rem;
  position: absolute;
  z-index: 9;
}
.img {
  margin-top: .5rem;
  width: 100%;
}
.btn {
  margin-top: .26rem;
  border-top: 1px solid #DCDCDC;
  padding-top: .6rem;
}
.btn button {
  background: none;
  border: none;
  background: #FFFFFF;
  border: .05rem solid #000000;
  border-radius: .6rem;
  height: 1.04rem;
  width: 100%;
  font-weight: 900;
  font-size: .23rem;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #000000;
}
</style>

