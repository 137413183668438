<template>
  <div id="app">
    <router-view />
    <van-tabbar v-if="$route.path != '/poster'" route>
      <van-tabbar-item replace to="/">
        <template >
          <img :src="icon[0]" style="width:.52rem" />
        </template>
        <p>益生菌</p>
      </van-tabbar-item>
       <van-tabbar-item replace to="/lipigo">
        <template >
          <img :src="icon[1]" style="width:.52rem" />
        </template>
        <p>LIPIGO</p>
      </van-tabbar-item>
       <van-tabbar-item replace to="/personal">
        <template >
          <img :src="icon[2]" style="width:.52rem" />
        </template>
        <p>个人中心</p>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
/* eslint-disable */
import personal from './assets/images/personal.png'
import lipigo from './assets/images/lipigo.png'
import index from './assets/images/index.png'
import { getQueryString } from './utils/util'
import { refreshWechatAppSession, referralUser } from './http/api'
let isWx = /micromessenger/i.test(navigator.userAgent);
export default {
  data() {
    return {
      active: 0,
      icon: [index, lipigo, personal],
      code: '',
      userInfo: {}
    }
  },
  created() {
    let code = getQueryString('code') || ''
    let referral = getQueryString('referral') || ''
    if (code) {
      this.code == code
      refreshWechatAppSession({variables: {code}}).then(res => {
        console.log(res)
        if (res.errors) {
          return
        } else {
          let userInfo = res.data.loginByWechatOa.user
          let sessionToken = res.data.loginByWechatOa.sessionToken
          let isNewbie = res.data.loginByWechatOa.isNewbie
          this.userInfo = userInfo
          localStorage.setItem('userInfo', JSON.stringify(userInfo))
          localStorage.setItem('sessionToken', sessionToken)
          if (isNewbie && referral) {
            this.referralUser(referral, userInfo.id)
          }
        }
      })
    } else {
      // let url = encodeURIComponent(window.location.href)
      // window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxa3aea9c5883b8ac5&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=state#wechat_redirect`
    }
    // if (isWx) {
    //   this.getWxConfig()
    // }

  },
  methods: {
    referralUser(referralUserId, userId) {
      referralUser({variables: {referralUserId, userId}}).then(res => {
        console.log(res)
      })
    }
  },
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  .container {
    padding-bottom: 2rem;
    margin: auto;
  }
  .van-tabbar {
    height: 1.76rem;

    .van-tabbar-item {
      font-size: 0.24rem;
      .van-tabbar-item__icon {
        font-size: .44rem;
      }
      .van-tabbar-item__text {
        text-align: center;
        color: #000;
        p {
          margin: 0;
          margin-top: 4px;
        }
      }
    }
  }
  .van-tabbar-item--active {
    color: #000;
  }
  .van-share-sheet__header {
    padding: .24rem .32rem .08rem;
  }
  .van-share-sheet__title {
    font-size: .28rem;
    margin-top: .16rem;
    line-height: .4rem;
  }
  .van-share-sheet__options {
    padding: .32rem 0 .32rem .16rem;
  }
  .van-share-sheet__icon {
    width: .96rem;
    height: .96rem;
    margin: 0 .32rem;
  }
  .van-share-sheet__name {
    font-size: .24rem;
    margin-top: .16rem;
    padding: 0 .08rem;
  }
  .van-share-sheet__cancel {
    font-size: .32rem;
    line-height: .96rem;
  }
}
</style>
