<!--index.wxml-->
<template>
  <div class="container">
    <div class="userinfo" v-if="hasUserInfo">
      <img v-if="userInfo.avatarUrl" class="userinfo-avatar" :src="userInfo.avatarUrl" mode="cover" />
      <div v-else class="userinfo-avatar-not"></div>
      <p class="userinfo-nickname">{{
        userInfo.nickname || userInfo.nickName || '未登录'
      }}</p>
    </div>
    <div class="tooles">
      <div class="tool-item" @click="toOrderList">
        <div class="img">
          <img src="@/assets/images/email.png" mode="widthFix" />
        </div>
        <div class="tool-title"> 我的订单</div>
      </div>
      <div class="tool-item">
        <div class="img">
          <img src="@/assets/images/gift.png" mode="widthFix" />
        </div>
        <div class="tool-title">引荐奖品兑换</div>
      </div>
      <div class="tool-item" @click="toChat">
        <div class="img">
          <img src="@/assets/images/help.png" mode="widthFix" />
        </div>
        <div class="tool-title" >FAQ</div>
      </div>
    </div>
    <div class="share">
      <div class="share-title">Share to My Buddy</div>
      <div class="poster">
        <img src="@/assets/images/poster.png" mode="widthFix" />
      </div>
    </div>
    <div class="button" @click="showShareSheet">
      转发给我的好友
    </div>
    <van-share-sheet
      v-model="showShare"
      title="立即分享给好友"
      :options="options"
      @select="onSelect"
      @close="onClose"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
      userInfo: {},
      hasUserInfo: false,
      showShare: false,
      options: [
        { name: "分享海报", icon: "poster" }
      ],
    };
  },
  mounted() {
    let userInfo = localStorage.getItem('userInfo')
    if (userInfo) {
      this.hasUserInfo = true
      this.userInfo = JSON.parse(userInfo)
    }
  },
  methods: {
    toChat() {
      window.location.href = 'https://shop1746899206.v.weidian.com/chat/#/chat?from_platform=h&invoke=thirdApp&to_platform=a&to_user_id=1746899206&im_enter=shop&to_id_type=0&wfr=decoration-qrcode&spider_token=4dea'
    },
    toOrderList() {
      window.location.href = 'https://weidian.com/user/order-new/list.php'
    },
    showShareSheet() {
      this.showShare = true
    },
    onClose() {
      this.showShare =false
    },
    onSelect(event) {
      if (event.icon == 'poster') {
        if (this.userInfo.id) {
          this.toPoster()
        } else {
          let url = encodeURIComponent(window.location.href)
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxa3aea9c5883b8ac5&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=state#wechat_redirect`
        }
      }
      this.onClose();
    },
    toPoster() {
      this.$router.push('/poster')
    },
  },
};
</script>

<style scoped>
.container {
  padding-bottom: .4rem;
}
.userinfo {
  margin-top: 0.88rem;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.userinfo-avatar {
  width: 2.06rem;
  height: 2.06rem;
  border-radius: 1.03rem;
  margin: auto;
  overflow: hidden;
}

.userinfo-avatar-not {
  width: 2.06rem;
  height: 2.06rem;
  border-radius: 1.03rem;
  margin: auto;
  overflow: hidden;
  background: #f1e1e5;
}

.userinfo-nickname {
  margin-top: 0.4rem;
  font-weight: 400;
  font-size: 0.36rem;
  line-height: 0.44rem;
  color: #000000;
}

.tooles {
  margin-top: 0.86rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.24rem;
  box-sizing: border-box;
}

.tool-item .img {
  width: 0.82rem;
  height: 0.82rem;
  border-radius: 0.41rem;
  background: #f1e1e5;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.tool-item img {
  width: 0.5rem;
}

.tool-title {
  width: 1.72rem;
  text-align: center;
  font-weight: 700;
  line-height: 0.36rem;
  margin-top: 0.24rem;
  font-size: 0.18rem;
  color: rgba(0, 0, 0, 0.8);
}

.share {
  margin-top: 0.52rem;
  padding: 0.34rem;
  box-sizing: border-box;
}

.share-title {
  font-weight: 700;
  font-size: 0.18rem;
  line-height: 0.36rem;
  letter-spacing: 0.03em;
  color: rgba(0, 0, 0, 0.8);
  border-bottom: 1px solid #dcdcdc;
}

.poster {
  margin-top: 0.32rem;
}

.poster img {
  width: 100%;
}

.button {
  margin: auto;
  margin-top: 0.28px;
  width: 6.86rem;
  height: 1.04rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 0.05rem solid #000000;
  border-radius: 0.52rem;
  font-weight: 900;
  font-size: 0.26rem;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #000000;
}

.button button {
  background: none;
  border: none;
  width: 100%;
  height: 100%;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.26rem;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  font-weight: 900;
}

.button button::after {
  display: none;
}

.button button::before {
  display: none;
}
</style>