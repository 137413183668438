import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { Collapse, CollapseItem, ShareSheet, Tabbar, TabbarItem } from 'vant';
import 'vant/lib/index.css';
import './libs/rem'
import wxutil from './libs/wxUtils'
wxutil.init()
import VConsole from 'vconsole'
new VConsole()
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(ShareSheet);
Vue.config.productionTip = false
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
